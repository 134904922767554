import { createI18n } from "vue-i18n";
import items_ja from "./ja/items";
import messages_ja from "./ja/messages";
import items_en from "./en/messages";
import messages_en from "./en/items";
import actions_en from "./en/actions";
import actions_ja from "./ja/actions";
import { getBrowserLang } from '../i18n/language';

const i18n = createI18n({
  _locale: getBrowserLang(),
  get locale() {
    return this._locale;
  },
  set locale(value) {
    this._locale = value;
  },
    allowComposition: true,
    globalInjection: true,
    legacy: false,
    messages: {
      ja: Object.assign(messages_ja, items_ja, actions_ja),
      en: Object.assign(messages_en, items_en, actions_en)
    },
});
export default i18n