import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
  { /* 標準コントロール */
    path: '/testBtn',
    name: 'testBtn',
    component: () => import('../components/testBtn.vue')
  },
  { /* ログイン画面 */
    path: '/',
    name: 'PF01C01D01',
    component: () => import('../views/PF01C01/PF01C01D01.vue')
  },
  { /* ログイン画面 */
    path: '/PF01C01D02',
    name: 'PF01C01D02',
    component: () => import('../views/PF01C01/PF01C01D02.vue')
  },
  { /* パスワード変更画面 */
    path: '/PF01C01D04',
    name: 'PF01C01D04',
    component: () => import('../views/PF01C01/PF01C01D04.vue')
  },
  { /*パスワードリセット */
    path: '/PF01C01D05',
    name: 'PF01C01D05',
    component: () => import('../views/PF01C01/PF01C01D05.vue')
  },
  { /* インデックス画面 */
    path: '/indexPage',
    name: 'indexPage',
    component: () => import('../views/indexPage.vue'),
    children: [{ /* トップページ */
      path: 'B001A01',
      name: 'B001A01',
      component: () => import('../views/b001/B001A01.vue')
    },
    { /* 企業マスタ検索画面 */
      path: 'PF01M01D01',
      name: 'PF01M01D01',
      component: () => import('../views/PF01M01/PF01M01D01.vue')
    },
    { /* 企業マスタ新規作成画面 */
      path: 'PF01M01D03',
      name: 'PF01M01D03',
      component: () => import('../views/PF01M01/PF01M01D03.vue')
    },
    { /* 企業マスタ照会画面 */
      path: 'PF01M01D04',
      name: 'PF01M01D04',
      component: () => import('../views/PF01M01/PF01M01D04.vue')
    },
    { /* 企業マスタ更新画面 */
      path: 'PF01M01D05',
      name: 'PF01M01D05',
      component: () => import('../views/PF01M01/PF01M01D05.vue')
    },
    { /* ユーザマスタ検索画面 */
      path: 'PF01M03D01',
      name: 'PF01M03D01',
      component: () => import('../views/PF01M03/PF01M03D01.vue')
    },
    { /* ユーザマスタ新規作成画面 */
      path: 'PF01M03D03',
      name: 'PF01M03D03',
      component: () => import('../views/PF01M03/PF01M03D03.vue')
    },
    { /* ユーザマスタ照会画面 */
      path: 'PF01M03D04',
      name: 'PF01M03D04',
      component: () => import('../views/PF01M03/PF01M03D04.vue')
    },
    { /* ユーザマスタ更新画面 */
      path: 'PF01M03D05',
      name: 'PF01M03D05',
      component: () => import('../views/PF01M03/PF01M03D05.vue')
    },
    { /* ロケーションマスタ検索画面 */
      path: 'PF01M02D01',
      name: 'PF01M02D01',
      component: () => import('../views/PF01M02/PF01M02D01.vue')
    },
    { /* ロケーションマスタ新規作成画面 */
      path: 'PF01M02D03',
      name: 'PF01M02D03',
      component: () => import('../views/PF01M02/PF01M02D03.vue')
    },
    { /* ロケーションマスタ照会画面 */
      path: 'PF01M02D04',
      name: 'PF01M02D04',
      component: () => import('../views/PF01M02/PF01M02D04.vue')
    },
    { /* ロケーションマスタ更新画面 */
      path: 'PF01M02D05',
      name: 'PF01M02D05',
      component: () => import('../views/PF01M02/PF01M02D05.vue')
    },
    // { /* 品目マスタ検索画面 */
    //   path: 'M004A01',
    //   name: 'M004A01',
    //   component: () => import( '../views/m004/M004A01.vue')
    // },
    // { /* 同一品目マスタ検索画面 */
    //   path: 'M005A01',
    //   name: 'M005A01',
    //   component: () => import( '../views/m005/M005A01.vue')
    // },
    { /* 連関情報検索画面 */
      path: 'PF01F10D01',
      name: 'PF01F10D01',
      component: () => import('../views/PF01F10/PF01F10D01.vue')
    },
    { /* 連関情報更新画面 */
      path: 'PF01F10D05',
      name: 'PF01F10D05',
      component: () => import('../views/PF01F10/PF01F10D05.vue')
    },

    { /* 原単位連関表照会(品目検索画面) */
      path: 'PF01F01D01',
      name: 'PF01F01D01',
      component: () => import('../views/PF01F01/PF01F01D01.vue')
    },
    { /* 原単位連関表照会(品目検索画面) 未確定 */
      path: 'PF01F01D02',
      name: 'PF01F01D02',
      component: () => import('../views/PF01F01/PF01F01D02.vue')
    },
    { /* 品目情報の履歴表示画面 */
      path: 'PF01F01D05',
      name: 'PF01F01D05',
      component: () => import('../views/PF01F01/PF01F01D05.vue')
    },
    { /* 品目情報の履歴表示画面 */
      path: 'PF01F01D06',
      name: 'PF01F01D06',
      component: () => import('../views/PF01F01/PF01F01D06.vue')
    },
    { /* 連関情報の履歴表示画面 */
      path: 'PF01F01D07',
      name: 'PF01F01D07',
      component: () => import('../views/PF01F01/PF01F01D07.vue')
    },
    { /* 原単位連関ワーク表検索画面 */
      path: 'PF01F16D01',
      name: 'PF01F16D01',
      component: () => import('../views/PF01F16/PF01F16D01.vue')
    },
    // { /* 原単位連関表確定画面 */
    //   path: 'G002A01',
    //   name: 'G002A01',
    //   component: () => import( '../views/g002/G002A01.vue')
    // },
    // { /* 原単位連関情報一括処理画面 */
    //   path: 'G003A01',
    //   name: 'G003A01',
    //   component: () => import( '../views/g003/G003A01.vue')
    // },
    //   { /* 品目グループマスタ検索画面 */
    //   path: 'M006A01',
    //   name: 'M006A01',
    //   component: () => import( '../views/m006/M006A01.vue')
    //  },
    //   { /* 品目グループマスタ新規作成画面 */
    //   path: 'M006A02',
    //   name: 'M006A02',
    //   component: () => import( '../views/m006/M006A02.vue')
    //   },
    //   { /* 品目グループマスタ照会画面 */
    //   path: 'M006A03',
    //   name: 'M006A03',
    //   component: () => import( '../views/m006/M006A03.vue')
    //   },
    //   { /* 品目グループマスタ編集画面 */
    //   path: 'M006A04',
    //   name: 'M006A04',
    //   component: () => import( '../views/m006/M006A04.vue')
    // },
    // { /* 母材・鋼材未到達リスト */
    //   path: 'B002A01',
    //   name: 'B002A01',
    //   component: () => import( '../views/b002/B002A01.vue')
    // },
    { /* 母材・鋼材未到達リスト */
      path: 'PF01F06D01',
      name: 'PF01F06D01',
      component: () => import('../views/PF01F06/PF01F06D01.vue')
    },
    // { /* 同一品目マスタ照会画面 */
    //   path: 'M005A03',
    //   name: 'M005A03',
    //   component: () => import( '../views/m005/M005A03.vue')
    // },
    // { /* 同一品目マスタ更新画面 */
    //   path: 'M005A04',
    //   name: 'M005A04',
    //   component: () => import( '../views/m005/M005A04.vue')
    // },
    // { /* 同一品目マスタ更新(同一品目検索)画面 */
    //   path: 'M005A06',
    //   name: 'M005A06',
    //   component: () => import( '../views/m005/M005A06.vue')
    // },
    // { /* 品目マスタ新規作成画面 */
    //   path: 'M004A02',
    //   name: 'M004A02',
    //   component: () => import( '../views/m004/M004A02.vue')
    // },
    // { /* 品目マスタ照会画面 */
    //   path: 'M004A03',
    //   name: 'M004A03',
    //   component: () => import( '../views/m004/M004A03.vue')
    // },
    // { /* 品目マスタ編集画面 */
    //   path: 'M004A04',
    //   name: 'M004A04',
    //   component: () => import( '../views/m004/M004A04.vue')
    // },
    { /* 品目検索画面 */
      path: 'PF01F07D01',
      name: 'PF01F07D01',
      component: () => import('../views/PF01F07/PF01F07D01.vue')
    },
    { /* 品目マスタ新規作成画面 */
      path: 'PF01F07D04',
      name: 'PF01F07D04',
      component: () => import('../views/PF01F07/PF01F07D04.vue')
    },
    { /* 品目マスタ照会画面 */
      path: 'PF01F07D05',
      name: 'PF01F07D05',
      component: () => import('../views/PF01F07/PF01F07D05.vue')
    },
    { /* 品目マスタ編集画面 */
      path: 'PF01F07D06',
      name: 'PF01F07D06',
      component: () => import('../views/PF01F07/PF01F07D06.vue')
    },
    { /* 部品表更新画面（アップロード）画面 */
      path: 'PF01F03D01',
      name: 'PF01F03D01',
      component: () => import('../views/PF01F03/PF01F03D01.vue')
    },
    { /* 母材・鋼材一括登録アップロード*/
      path: 'PF01F14D01',
      name: 'PF01F14D01',
      component: () => import('../views/PF01F14/PF01F14D01.vue')
    },
    { /* OEM連携（アップロード）画面 */
      path: 'PF01F02D01',
      name: 'PF01F02D01',
      component: () => import('../views/PF01F02/PF01F02D01.vue')
    },
    { /* 承認状況一覧検索画面 */
      path: 'PF01F15D02',
      name: 'PF01F15D02',
      component: () => import('../views/PF01F15/PF01F15D02.vue')
    },
    { /* 母材グループマスタ検索画面 */
      path: 'PF01F09D01',
      name: 'PF01F09D01',
      component: () => import('../views/PF01F09/PF01F09D01.vue')
    },
    { /* 母材グループマスタ新規作成画面 */
      path: 'PF01F09D03',
      name: 'PF01F09D03',
      component: () => import('../views/PF01F09/PF01F09D03.vue')
    },
    { /* 母材グループマスタ編集画面 */
      path: 'PF01F09D06',
      name: 'PF01F09D06',
      component: () => import('../views/PF01F09/PF01F09D06.vue')
    },
    { /* 原単位連関表承認依頼リスト抽出画面 */
      path: 'PF01F13D01',
      name: 'PF01F13D01',
      component: () => import('../views/PF01F13/PF01F13D01.vue')
    },
    { /* 原単位連関表承認依頼リスト抽出画面 */
    path: 'PF01F13D01-2',
    name: 'PF01F13D01-2',
    component: () => import('../views/PF01F13/PF01F13D03.vue')
  },
  { /* 納入日程表（アップロード）画面 */
    path: 'PF02F01D01',
    name: 'PF02F01D01',
    component: () => import('../views/PF02F01/PF02F01D01.vue')
  },
  { /* 車両生産台数（アップロード）画面 */
    path: 'PF02F02D01',
    name: 'PF02F02D01',
    component: () => import('../views/PF02F02/PF02F02D01.vue')
  },
  { /* 納入日程表（補用品）アップロード画面 */
    path: 'PF02F03D01',
    name: 'PF02F03D01',
    component: () => import('../views/PF02F03/PF02F03D01.vue')
  },
  { /* 部番－GLNO連関表 登録アップロード画面 */
    path: 'PF02F12D01',
    name: 'PF02F12D01',
    component: () => import('../views/PF02F12/PF02F12D01.vue')
  },
  { /* 業連車両-GLNO関連表アップロード画面 */
    path: 'PF02F13D01',
    name: 'PF02F13D01',
    component: () => import('../views/PF02F13/PF02F13D01.vue')
  },
  { /* 鋼材月次所要量 承認画面 */
    path: 'PF02F05D01-1',
    name: 'PF02F05D01-1',
    component: () => import('../views/PF02F05/PF02F05D01.vue')
  },
  { /* 鋼材月次所要量 承認・照会 */
  path: 'PF02F05D01',
  name: 'PF02F05D01',
  component: () => import('../views/PF02F05/PF02F05D01.vue')
},
  { /* 鋼材月次所要量_未承認 照会画面 */
    path: 'PF02F06D01',
    name: 'PF02F06D01',
    component: () => import('../views/PF02F06/PF02F06D01.vue')
  },
  { /* 鋼材月次所要量_未承認 照会画面 */
    path: 'PF02F06D02',
    name: 'PF02F06D02',
    component: () => import('../views/PF02F06/PF02F06D02.vue')
  },
  { /* 月次所要量計算結果確認検索画面 */
    path: 'PF02F04D01',
    name: 'PF02F04D01',
    component: () => import('../views/PF02F04/PF02F04D01.vue')
  },
  { /* 鋼材月次所要量ダウンロード画面 */
    path: 'PF02F07D01',
    name: 'PF02F07D01',
    component: () => import('../views/PF02F07/PF02F07D01.vue')
  },
  { /*母材月次発注量 登録画面 */
    path: 'PF02F08D01',
    name: 'PF02F08D01',
    component: () => import('../views/PF02F08/PF02F08D01.vue')
  },
  { /* 母材月次発注量 承認画面 */
    path: 'PF02F09D01',
    name: 'PF02F09D01',
    component: () => import('../views/PF02F09/PF02F09D01.vue')
  },
  { /* 母材月次発注量 承認画面 */
  path: 'PF02F09D01-1',
  name: 'PF02F09D01-1',
  component: () => import('../views/PF02F09/PF02F09D01.vue')
  },
  { /* 母材月次発注量 照会画面 */
    path: 'PF02F10D01',
    name: 'PF02F10D01',
    component: () => import('../views/PF02F10/PF02F10D01.vue')
  },
  { /* ユーザ・グループマスタ 検索画面 */
    path: 'PF03F10D01',
    name: 'PF03F10D01',
    component: () => import('../views/PF03F10/PF03F10D01.vue')
},
  {
    path: 'PF03F01D01',
    name: 'PF03F01D01',
    component: () => import('../views/PF03F01/PF03F01D01.vue')
  },
  { /* 共通納期管理検索 */
    path: 'PF03F05D01',
    name: 'PF03F05D01',
    component: () => import('../views/PF03F05/PF03F05D01.vue')
  },
  { /* 共通納期管理ダウンロード検索画面 */
    path: 'PF03F05D02',
    name: 'PF03F05D02',
    component: () => import('../views/PF03F05/PF03F05D02.vue')
  },
  { /* 出荷実績(出荷予定)登録・変更画面 */
    path: 'PF03F06D01',
    name: 'PF03F06D01',
    component: () => import('../views/PF03F06/PF03F06D01.vue')
  },
  { /* 通知一覧検索画面 */
    path: 'PF03F03D03',
    name: 'PF03F03D03',
    component: () => import('../views/PF03F03/PF03F03D02.vue')
  },
  { /* グループマスタ 照会画面 */
    path: 'PF03F09D01',
    name: 'PF03F09D01',
    component: () => import('../views/PF03F09/PF03F09D01.vue')
  },
  { /* 共通納期管理照会画面 */
    path: 'PF03F05D03',
    name: 'PF03F05D03',
    component: () => import('../views/PF03F05/PF03F05D03.vue')
  },
  { /* 共通納期管理詳細画面(ポップアップ) */
    path: 'PF03F05D04',
    name: 'PF03F05D04',
    component: () => import('../views/PF03F05/PF03F05D04.vue')
  },
  { /* アップロードバッチ照会画面 */
    path: 'PF03F08D01',
    name: 'PF03F08D01',
    component: () => import('../views/PF03F08/PF03F08D01.vue')
  },
  { /* ダウンロードバッチ照会画面 */
    path: 'PF03F08D03',
    name: 'PF03F08D03',
    component: () => import('../views/PF03F08/PF03F08D03.vue')
  },
  { /* ユーザ・グループマスタ アップロード */
    path: 'PF03F10D01',
    name: 'PF03F10D01',
    component: () => import('../views/PF03F10/PF03F10D01.vue')
  },
  { /* リードタイムマスタ(品目コード別) 検索画面 */
    path: 'PF03F14D01',
    name: 'PF03F14D01',
    component: () => import('../views/PF03F14/PF03F14D01.vue')
  },
  { /* 納入先マスタ 検索画面 */
    path: 'PF03F20D01',
    name: 'PF03F20D01',
    component: () => import('../views/PF03F20/PF03F20D01.vue')
  },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  store.dispatch('setPreviousRoute', from);
  next();
});

export default router
