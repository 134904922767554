import { createStore } from 'vuex'
import ja from 'element-plus/lib/locale/lang/ja';
import en from 'element-plus/lib/locale/lang/en';
import { DateFormat } from '@/constant/common';
import i18n from '@/i18n';

export default createStore({
  state () {
    return {
      storeDisabledFlag: false,
      countNum:0,
      addDialogFlag:false,
      isCollapse:false,
      locale: ja,
      lang: 'ja',
      ymFormat: DateFormat.YYYY_MM,
      ymdFormat: DateFormat.YYYY_MM_DD,
      previousRoute: null,
      isDialogOpen: false,
      notifCount: 0,
      isBadgeHidden: true,
      notificationDataList: [],
      notifTableCurrentPage: 1
    }
  },
  getters: {
    isCollapse: state => state.isCollapse,
    locale: state => state.locale,
    lang: state => state.lang,
    ymFormat: state => state.ymFormat,
    ymdFormat: state => state.ymdFormat,
    isDialogOpen: state => state.isDialogOpen,
    notifCount: state => state.notifCount,
    isBadgeHidden: state => state.isBadgeHidden,
    notificationDataList: state => state.notificationDataList,
    notifTableCurrentPage: state => state.notifTableCurrentPage
  },
  mutations: {
    setisCollapseData(state, data) {
      state.isCollapse = data
    },
    setLocale(state, locale) {
      state.locale = locale
    },
    setLang(state, lang) {
      state.lang = lang
      if (lang === 'ja') {
        state.locale = ja
        state.ymFormat = DateFormat.YYYY_MM
        state.ymdFormat = DateFormat.YYYY_MM_DD
      }
      if (lang === 'en') {
        state.locale = en
        state.ymFormat = DateFormat.MM_YYYY
        state.ymdFormat = DateFormat.DD_MM_YYYY
      }
      i18n.global.locale.value = lang;
    },
    setYmFormat(state, format) {
      state.ymFormat = format
    },
    setYmdFormat(state, format) {
      state.ymdFormat = format
    },
    setPreviousRoute(state, route) {
      state.previousRoute = route;
    },
    setDialogOpen(state, isOpen) { 
      state.isDialogOpen = isOpen;
    }, 
    setNotifCount(state, count) {
      state.notifCount = count;
    },
    setIsBadgeHidden(state, data) { 
      state.isBadgeHidden = data
    },
    setNotificationDataList(state, payload) {
      state.notificationDataList = payload;
    },
    setNotifTableCurrentPage(state, pageNum) {
      state.notifTableCurrentPage = pageNum;
    }
  },
  actions: {
    setPreviousRoute({ commit }, route) {
      commit('setPreviousRoute', route);
    }
  },
  modules: {
  }
})